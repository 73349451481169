<script>
import axios from "axios";
import Layout from "./Layout.svelte";
import { addMessages, init } from "svelte-i18n";
import SwiperCore, { Navigation, Pagination } from "swiper";
import jp from "./locale/jp.json";
import en from "./locale/en.json";
import smoothscroll from "smoothscroll-polyfill";

//TODO: Problem with importing swiper css
//TODO: add favicon

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

// kick off the polyfill!
smoothscroll.polyfill();

// Create default axios options
axios.defaults.baseURL = "https://n1.noxtton.com";
axios.defaults.headers.common["x-api-key"] =
  "452d4a5f041fcf1822b0f9976364dfb040f0f2b0bc8c9164";

addMessages("en", en);
addMessages("jp", jp);

const localeType = localStorage.getItem("locale");

init({
  fallbackLocale: "jp",
  initialLocale: localeType || "jp",
});
</script>

<div>
  <Layout />
</div>
