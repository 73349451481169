<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.business-environment-icons {
  width: 100%;
  padding: 4.8rem 4.8rem 6.4rem;

  @include tablet {
    padding: 8.8rem 0 9.6rem;
  }
}

h2 {
  @include h-28-semi-bold;

  text-align: center;
}

h3 {
  @include h-18-semi-bold;

  text-align: center;
  padding-top: 2.4rem;
  font-weight: 500;

  @include tablet {
    padding-top: 4.4rem;
  }
}

.icons {
  margin: 0 auto;
  width: 100%;

  @include tablet {
    padding-top: 2rem;
    width: 88rem;
  }

  @include desktop-sm {
    width: 111.2rem;
  }
}

ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

li {
  text-align: center;
  width: 100%;
  padding-top: 6rem;

  @include tablet {
    padding-top: 5.2rem;
    width: 24rem;
  }

  @include desktop-sm {
    width: 32.8rem;
  }
}

img {
  width: 6.4rem;
}

p {
  @include p-14;

  padding-top: 3.2rem;
}

strong {
  @include p-14-semi-bold;

  color: $jp-red;
}
</style>

<script lang="ts">
import type {
  TaxationSystemIconsDataInterface,
  TaxationSystemIconsInterface,
} from "../interfaces/taxationSystemInterfaces";
import axios from "axios";
import { _ } from "svelte-i18n";
import { onMount } from "svelte";
import { tData } from "../util/tData";
import navigation from "../store/navigation";
import { locale } from "svelte-i18n";
import type { LocaleType } from "../types";

let localeType: LocaleType;
let businessEnvEl: HTMLDivElement;
let taxationSystemIconsData: TaxationSystemIconsDataInterface[] = [];

onMount(async () => {
  navigation.setElement(businessEnvEl, "businessEnvironment");

  const {
    data: taxationSystemIconsResult,
  }: { data: TaxationSystemIconsDataInterface[] } = await axios.get(
    "/embassy-japan-tax-icons"
  );

  taxationSystemIconsData = taxationSystemIconsResult;
});

locale.subscribe((locale) => {
  localeType = locale;
});

$: taxationSystemIcons = taxationSystemIconsData.map((item) => ({
  ...tData<TaxationSystemIconsInterface>(item, localeType),
  id: item.id,
}));
</script>

<div class="business-environment-icons" bind:this="{businessEnvEl}">
  <h2>{$_("businessEnvironment")}</h2>
  {#if localeType === "en"}
    <h3>{$_("taxationSystem")}</h3>
  {/if}

  <div class="icons">
    <ul>
      {#each taxationSystemIcons as item (item.title)}
        <li>
          <img src="{item.icon}" alt="{item.title}" />
          <p>{item.title}{" "}<strong>{item.value}</strong></p>
        </li>
      {/each}
    </ul>
  </div>
</div>
