<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

header {
  width: 100%;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-header;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
}

nav {
  width: 100%;
  flex: 1;
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  @include tablet() {
    justify-content: center;
  }
}

ul {
  display: flex;
  white-space: nowrap;
}

li {
  padding: 0 1.6rem;
  position: relative;
  display: flex;
  align-items: center;
}

.nav-button {
  @include p-14;

  color: rgba($jp-white, 0.4);
}

.lang-wrapper {
  position: relative;
  margin: 0 1.6rem;
  color: $jp-white;

  @include tablet {
    position: absolute;
    left: calc(50% + 43rem);
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    .lang-button {
      border-radius: 1.4rem 1.4rem 0 0;
    }
    .lang-button--dropdown {
      display: block;
      border-radius: 0 0 1.4rem 1.4rem;
    }
  }

  &--scroll {
    color: $jp-black;

    .lang-button--dropdown {
      background-color: $jp-white;
    }
  }
}

.lang-button {
  @include p-14-medium;

  height: 2.8rem;
  padding: 0 0.8rem;
  display: flex;
  align-items: center;
  color: inherit;
  border: $border-style;
  text-align: left;
  letter-spacing: 0.01em;
  border-radius: 1.4rem;
  white-space: nowrap;

  &--dropdown {
    border: $border-style;
    border-top: none;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    display: none;
  }

  img {
    width: 1.4rem;
    height: 1.4rem;
    margin-left: 0.4rem;
  }
}

.button-scroll {
  color: $jp-grey-300;
}

.active {
  font-weight: 600;
  color: $jp-white;
}

.scroll {
  background-color: $jp-white;
  border-bottom: $border-style;

  .active {
    color: $jp-black;
  }
}
</style>

<script lang="ts">
import { _, locale } from "svelte-i18n";
import { NAV_LIST } from "../constants/navigation";
import navigation from "../store/navigation";
import type { NavName, Elements, LocaleType } from "../types";

let navigationsEl: HTMLElement;
let scrollEl: HTMLElement;

let y: number;
let activeNavigationIndex: number;
let lang: string;
let elements: Elements;

navigation.subscribe((navigation) => {
  activeNavigationIndex = navigation.activeIndex;
  elements = navigation.elements;
});

locale.subscribe((activeLang) => {
  lang = activeLang;
});

const handleNavigationSet = (index: number) => {
  if (navigationsEl && scrollEl) {
    const nodes = navigationsEl.childNodes;
    const node = nodes[index] as HTMLElement;

    if (node) {
      const { x: scrollStartPoint, width: scrollWidth } =
        scrollEl.getBoundingClientRect();
      const { x: nodeStartPoint, width: nodeWidth } =
        node.getBoundingClientRect();

      const nodeEndPoint = nodeStartPoint + nodeWidth;
      const scrollEndPoint = scrollStartPoint + scrollWidth;

      if (nodeStartPoint < scrollStartPoint || nodeEndPoint > scrollEndPoint) {
        scrollEl.scrollTo({
          left: node.offsetLeft,
          behavior: "smooth",
        });
      }
    }
  }

  navigation.setNavigation(index);
};

const handleClick = (index: number) => {
  const key = Object.keys(NAV_LIST)[index] as NavName;

  if (elements && elements[key]) {
    const offsetTop = elements[key].offsetTop;

    window.scrollTo({
      left: 0,
      top: offsetTop - 80,
      behavior: "smooth",
    });
  }

  handleNavigationSet(index);
};

const setLanguage = (localeType: LocaleType) => {
  localStorage.setItem("locale", localeType);
  locale.set(localeType);
};

$: isScrolled = y > 0;
$: if (isScrolled) {
  if (elements) {
    const mappedPositions = Object.values(elements)
      .map((value, i) => (i === 0 ? 0 : value.offsetTop)) // set first item position as 0
      .reverse(); // reverse array to make comparison easier

    const index =
      mappedPositions.length -
      1 -
      mappedPositions.findIndex((position) => position - 80 <= y);

    if (activeNavigationIndex !== index) {
      handleNavigationSet(index);
    }
  }
}
</script>

<svelte:window bind:scrollY="{y}" />
<header class:scroll="{isScrolled}">
  <div class="wrapper">
    <nav bind:this="{scrollEl}">
      <ul bind:this="{navigationsEl}">
        {#each Object.values(NAV_LIST) as nav, i (nav.name)}
          <li>
            <button
              class="nav-button"
              class:button-scroll="{isScrolled}"
              class:active="{activeNavigationIndex === i}"
              on:click="{() => handleClick(i)}">
              {$_(nav.name)}
            </button>
          </li>
        {/each}
      </ul>
    </nav>

    <div class="lang-wrapper" class:lang-wrapper--scroll="{isScrolled}">
      <button class="lang-button">
        {$_(lang === "en" ? "eng" : "jap")}

        {#if isScrolled}
          <img src="/svgs/arrow-down-s-line-white.svg" alt="arrow icon" />
        {:else}
          <img src="/svgs/arrow-down-s-line.svg" alt="arrow icon" />
        {/if}
      </button>
      <button
        class="lang-button lang-button--dropdown"
        on:click="{() => setLanguage(lang === 'en' ? 'jp' : 'en')}">
        {$_(lang === "en" ? "jap" : "eng")}
      </button>
    </div>
  </div>
</header>
