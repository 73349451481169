<style lang="scss" global>
@import "../styles/vars";
@import "../styles/mixins";

.trade-opportunities {
  padding: 4.8rem 1.6rem 6.4rem;

  @include tablet {
    padding: 8.8rem 0 7.6rem;
  }

  &__info {
    @include tablet {
      display: flex;
      padding-top: 8.8rem;
    }
  }

  h2 {
    @include h-28-semi-bold;

    text-align: center;
  }

  h3 {
    @include h-18-semi-bold;

    line-height: 2.6rem;
  }

  ul {
    padding-top: 4.4rem;
    max-width: 72rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include tablet {
      padding-top: 4.8rem;
    }
  }

  li {
    @include h-18;

    height: 4.8rem;
    padding: 1.6rem;
    border-radius: 2.4rem;
    border: 0.1rem solid $jp-black;
    margin: 0.6rem;
    display: flex;
    align-items: center;
  }

  &__title {
    @include p-14;

    max-width: 60.8rem;
    padding-top: 1.4rem;
    margin: 0 auto;
    text-align: center;

    @include tablet {
      padding-top: 0.8rem;
    }
  }

  &__info-item {
    padding-top: 4.8rem;

    &:first-child {
      padding-top: 4.4rem;
    }

    @include tablet {
      padding: 0;

      &:first-child {
        padding-top: 0;
        padding-right: 3.2rem;
      }

      &:last-child {
        padding-left: 3.2rem;
      }
    }
  }

  &__info-text {
    padding-top: 2.4rem;

    p {
      @include p-14;

      text-align: left;
    }
  }

  &__gallery {
    position: relative;
  }

  &__icon {
    height: 9.6rem;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: $z-over-default;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;

    &--left {
      left: 25%;
      transform: translate(-50%, -50%);
    }

    &--right {
      right: 25%;
      transform: translate(50%, -50%);
    }

    h1 {
      font-size: 1.8rem;
      font-weight: 500;
      color: $jp-white;
      padding-top: 1rem;

      @include tablet {
        @include h-28-semi-bold;
      }
    }

    img {
      width: 4.8;
      height: 4.8;
    }
  }
}
</style>

<script lang="ts">
import type {
  InvestmentTradeDataInterface,
  InvestmentTradeInterface,
  InvestmentTradeInfoInterface,
  InvestmentTradeInfoDataInterface,
  InvestmentTradeGalleryInterface,
  InvestmentTradeGalleryDataInterface,
} from "../interfaces/investmentTradeInterfaces";
import type { GalleryImageInterface } from "../interfaces/galleryImageInterfaces";
import axios from "axios";
import { _ } from "svelte-i18n";
import { onMount } from "svelte";
import ContentWrapper from "../components/ContentWrapper.svelte";
import { tData } from "../util/tData";
import ImageGallery from "../components/ImageGallery.svelte";
import navigation from "../store/navigation";
import { locale } from "svelte-i18n";
import type { LocaleType } from "../types";

let localeType: LocaleType;
let tradeOpportunitiesEl: HTMLDivElement;
let investmentTradeData: InvestmentTradeDataInterface[] = [
  undefined,
  undefined,
];
let investmentTradeInfoData: InvestmentTradeInfoDataInterface[] = [];
let investmentTradeGallery: GalleryImageInterface[] = [];

onMount(async () => {
  navigation.setElement(tradeOpportunitiesEl, "investmentTradeOpportunities");

  const {
    data: investmentTradeResult,
  }: { data: InvestmentTradeDataInterface[] } = await axios.get(
    "/embassy-japan-investment-trade"
  );
  investmentTradeData = investmentTradeResult;

  const {
    data: investmentTradeInfoResult,
  }: { data: InvestmentTradeInfoDataInterface[] } = await axios.get(
    "/embassy-japan-inv-trade-info"
  );
  investmentTradeInfoData = investmentTradeInfoResult;

  const {
    data: investmentTradeGalleryResult,
  }: { data: InvestmentTradeGalleryDataInterface[] } = await axios.get(
    "/embassy-japan-investment-trade-gallery"
  );
  investmentTradeGallery = investmentTradeGalleryResult.map((item) => ({
    ...tData<InvestmentTradeGalleryInterface>(item),
    id: item.id,
  }));
});

locale.subscribe((locale) => {
  localeType = locale;
});

$: investmentTradeInfo = investmentTradeInfoData.map((item) =>
  tData<InvestmentTradeInfoInterface>(item, localeType)
);

$: [title, ...restInvestmentTrade] = investmentTradeData.map((item) =>
  tData<InvestmentTradeInterface>(item, localeType)
);
</script>

<div class="trade-opportunities" bind:this="{tradeOpportunitiesEl}">
  <h2>{$_("investmentTradeOpportunities")}</h2>
  {#if title && restInvestmentTrade}
    <p class="trade-opportunities__title">{title.text}</p>

    <ul>
      {#each restInvestmentTrade as item, i (i)}
        <li>{item.text}</li>
      {/each}
    </ul>
  {/if}

  {#if investmentTradeInfo && investmentTradeInfo.length}
    <ContentWrapper>
      <div class="trade-opportunities__info">
        {#each investmentTradeInfo as item (item.title)}
          <div class="trade-opportunities__info-item">
            <h3>{item.title}</h3>
            <div class="trade-opportunities__info-text">{@html item.text}</div>
          </div>
        {/each}
      </div>
    </ContentWrapper>
  {/if}
</div>
{#if investmentTradeGallery && investmentTradeGallery.length}
  <div class="trade-opportunities__gallery">
    <ImageGallery images="{investmentTradeGallery}" id="trade-opportunities" />

    <div class="trade-opportunities__icon trade-opportunities__icon--left">
      <img src="/svgs/leaf-line.svg" alt="Leaf icon" />
      <h1>{$_("greenEnergy")}</h1>
    </div>
    <div class="trade-opportunities__icon trade-opportunities__icon--right">
      <img src="/svgs/decarbonization.svg" alt="Decarbonization" />
      <h1>{$_("decarbonization")}</h1>
    </div>
  </div>
{/if}
