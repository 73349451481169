<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.taxation-system {
  padding: 2.4rem 1.6rem 6.4rem;
  background-color: $jp-blue;

  @include tablet {
    padding: 5.2rem 0 9.6rem;
  }
}
</style>

<script lang="ts">
import type {
  TaxationSystemDataInterface,
  TaxationSystemInterface,
} from "../interfaces/taxationSystemInterfaces";
import axios from "axios";
import { _ } from "svelte-i18n";
import { onMount } from "svelte";
import { tData } from "../util/tData";
import TextList from "../components/TextList.svelte";
import { locale } from "svelte-i18n";
import type { LocaleType } from "../types";

let localeType: LocaleType;
let taxationSystemIconsData: TaxationSystemDataInterface[] = [];

onMount(async () => {
  const {
    data: taxationSystemIconsResult,
  }: { data: TaxationSystemDataInterface[] } = await axios.get(
    "/embassy-japan-taxation-system"
  );

  taxationSystemIconsData = taxationSystemIconsResult;
});

locale.subscribe((locale) => {
  localeType = locale;
});

$: taxationSystem = taxationSystemIconsData.map((item) => ({
  ...tData<TaxationSystemInterface>(item, localeType),
  id: item.id,
}));
</script>

<div class="taxation-system">
  <TextList list="{taxationSystem}" />
</div>
