<style lang="scss" global>
@import "../styles/vars";
@import "../styles/mixins";

.investments {
  padding: 4.8rem 1.6rem 4.4rem;
  background-color: $jp-blue;

  @include tablet {
    padding: 8.8rem 0 7.6rem;
  }

  h3 {
    @include h-16-semi-bold;

    text-align: center;
    max-width: 60.8rem;
    margin: 0 auto;
    line-height: 2.4rem;
  }

  &__content {
    padding-top: 2rem;

    @include tablet {
      padding-top: 7.2rem;
    }

    ul {
      @include tablet {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }

      li {
        padding-top: 2.4rem;

        @include tablet {
          padding-top: 0;
          max-width: 24rem;
        }

        @include desktop-sm {
          max-width: 32rem;
        }

        p {
          @include p-14;

          &:not(:first-child) {
            padding-top: 2.4rem;
          }

          strong {
            @include p-14-semi-bold;
          }
        }
      }
    }
  }
}
</style>

<script lang="ts">
import type {
  InvestmentsDataInterface,
  InvestmentsInterface,
  InvestmentsGalleryDataInterface,
  InvestmentsGalleryInterface,
} from "../interfaces/investmentsInterfaces";
import type { GalleryImageInterface } from "../interfaces/galleryImageInterfaces";
import axios from "axios";
import { onMount } from "svelte";
import { tData } from "../util/tData";
import ImageGallery from "../components/ImageGallery.svelte";
import ContentWrapper from "../components/ContentWrapper.svelte";
import { locale } from "svelte-i18n";
import type { LocaleType } from "../types";

let localeType: LocaleType;
let investmentsData: InvestmentsDataInterface;
let investmentsGallery: GalleryImageInterface[] = [];

onMount(async () => {
  const { data: investmentsResult }: { data: InvestmentsDataInterface[] } =
    await axios.get("/embassy-japan-inc-investments");
  investmentsData = investmentsResult[0];

  const {
    data: freeTradeGalleryData,
  }: { data: InvestmentsGalleryDataInterface[] } = await axios.get(
    "/embassy-japan-investments-gallery"
  );
  investmentsGallery = freeTradeGalleryData.map((item) => ({
    ...tData<InvestmentsGalleryInterface>(item),
    id: item.id,
  }));
});

locale.subscribe((locale) => {
  localeType = locale;
});

$: investments = tData<InvestmentsInterface>(investmentsData, localeType);
</script>

<div class="investments">
  {#if investments}
    <h3>{investments.title}</h3>
    <div class="investments__content">
      <ContentWrapper>
        <ul>
          <li>{@html investments.textOne}</li>
          <li>{@html investments.textTwo}</li>
          <li>{@html investments.textThree}</li>
        </ul>
      </ContentWrapper>
    </div>
    <ul></ul>
  {/if}
</div>
{#if investmentsGallery.length}
  <ImageGallery
    images="{investmentsGallery}"
    id="investments"
    headerType="blue" />
{/if}
