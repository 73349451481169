<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.taxation-system-logos {
  background-color: rgba($jp-grey-200, 0.15);
}
</style>

<script lang="ts">
import type {
  TaxationSystemLogosDataInterface,
  TaxationSystemLogosInterface,
} from "../interfaces/taxationSystemInterfaces";
import type { LogoInterface } from "../interfaces/logoInterfaces";
import axios from "axios";
import { onMount } from "svelte";
import { tData } from "../util/tData";
import LogoHorizontalScroller from "../components/LogoHorizontalScroller.svelte";

let taxationSystemLogos: LogoInterface[] = [];

onMount(async () => {
  const {
    data: taxationSystemIconsData,
  }: { data: TaxationSystemLogosDataInterface[] } = await axios.get(
    "/embassy-japan-taxation-system-logos"
  );
  taxationSystemLogos = taxationSystemIconsData.map((item) => ({
    ...tData<TaxationSystemLogosInterface>(item),
    id: item.id,
  }));
});
</script>

<div class="taxation-system-logos">
  <LogoHorizontalScroller logos="{taxationSystemLogos}" />
</div>
