<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.bilateral-logos {
  background-color: rgba($jp-grey-200, 0.15);
}
</style>

<script lang="ts">
import type {
  BilateralLogoDataInterface,
  BilateralLogoInterface,
  BilateralGalleryDataInterface,
  BilateralGalleryInterface,
} from "../interfaces/bilateralInterfaces";
import type { LogoInterface } from "../interfaces/logoInterfaces";
import type { GalleryImageInterface } from "../interfaces/galleryImageInterfaces";
import axios from "axios";
import { onMount } from "svelte";
import { tData } from "../util/tData";
import LogoHorizontalScroller from "../components/LogoHorizontalScroller.svelte";
import ImageGallery from "../components/ImageGallery.svelte";

let bilateralLogos: LogoInterface[] = [];
let bilateralGallery: GalleryImageInterface[] = [];

onMount(async () => {
  const { data: bilateralLogosData }: { data: BilateralLogoDataInterface[] } =
    await axios.get("/embassy-japan-bilateral-logos");
  bilateralLogos = bilateralLogosData.map((item) => ({
    ...tData<BilateralLogoInterface>(item),
    id: item.id,
  }));

  const {
    data: bilateralGalleryData,
  }: { data: BilateralGalleryDataInterface[] } = await axios.get(
    "/embassy-japan-bilateral-gallery"
  );
  bilateralGallery = bilateralGalleryData.map((item) => ({
    ...tData<BilateralGalleryInterface>(item),
    id: item.id,
  }));
});
</script>

<div class="bilateral-logos">
  <LogoHorizontalScroller logos="{bilateralLogos}" paddingBottom="small" />
</div>
{#if bilateralGallery.length}
  <ImageGallery
    images="{bilateralGallery}"
    id="bilateral-logos"
    headerType="grey" />
{/if}
