<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.title-value-list {
  width: 100%;
}

ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

li {
  padding-top: 2.4rem;
  width: 100%;

  @include tablet {
    max-width: 24rem;
  }

  @include desktop-sm {
    max-width: 32rem;
  }

  div {
    @include p-14;

    strong {
      @include p-14-semi-bold;
    }
  }
}

.padding-small {
  @include desktop-sm {
    padding-top: 3.2rem;
  }
}
.padding-large {
  @include desktop-sm {
    padding-top: 6.4rem;
  }
}
</style>

<script lang="ts">
import ContentWrapper from "./ContentWrapper.svelte";
import type { TextItemInterface } from "../interfaces/textItemInterfaces";

export let list: TextItemInterface[] = [];
export let padding: "small" | "large" = "small";
</script>

<div class="title-value-list">
  <ContentWrapper>
    <ul>
      {#each list as item (item.id)}
        <li class="{`padding-${padding}`}">
          <div>{@html item.text}</div>
        </li>
      {/each}
    </ul>
  </ContentWrapper>
</div>
