import { writable } from 'svelte/store';
const createNavigation = () => {
    const navigation = writable({
        activeIndex: 0,
        elements: {
            countryProfile: null,
            businessEnvironment: null,
            bilateralRelations: null,
            investmentTradeOpportunities: null,
            tourismPotential: null,
        }
    });
    return {
        subscribe: navigation.subscribe,
        setNavigation: (activeIndex) => {
            navigation.update(navigation => (Object.assign(Object.assign({}, navigation), { activeIndex })));
        },
        setElement: (element, key) => {
            navigation.update(navigation => (Object.assign(Object.assign({}, navigation), { elements: Object.assign(Object.assign({}, navigation.elements), { [key]: element }) })));
        }
    };
};
export default createNavigation();
