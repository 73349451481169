export const tData = (data, localeType = 'en') => {
    if (!data) {
        return undefined;
    }
    if (data[localeType]) {
        const result = {};
        Object.keys(data).forEach(key => {
            result[key] = data[localeType][key] || data["en"][key];
        });
        return data[localeType];
    }
    return data[localeType]["en"];
};
