<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.free-trade {
  padding: 4.8rem 1.6rem 4.4rem;

  @include tablet {
    padding: 8.8rem 0 7.6rem;
  }
}

h3 {
  @include h-18-semi-bold;

  text-align: center;

  @include tablet {
    padding-bottom: 0.8rem;
  }
}
</style>

<script lang="ts">
import type {
  FreeTradeDataInterface,
  FreeTradeInterface,
  FreeTradeGalleryDataInterface,
  FreeTradeGalleryInterface,
} from "../interfaces/freeTradeInterfaces";
import type { GalleryImageInterface } from "../interfaces/galleryImageInterfaces";
import axios from "axios";
import { _ } from "svelte-i18n";
import { onMount } from "svelte";
import { tData } from "../util/tData";
import TextList from "../components/TextList.svelte";
import ImageGallery from "../components/ImageGallery.svelte";
import { locale } from "svelte-i18n";
import type { LocaleType } from "../types";

let localeType: LocaleType;
let freeTradeData: FreeTradeDataInterface[] = [];
let freeTradeGallery: GalleryImageInterface[] = [];

onMount(async () => {
  const { data: freeTradeResult }: { data: FreeTradeDataInterface[] } =
    await axios.get("/embassy-japan-free-trade");

  freeTradeData = freeTradeResult;

  const {
    data: freeTradeGalleryData,
  }: { data: FreeTradeGalleryDataInterface[] } = await axios.get(
    "/embassy-japan-free-trade-gallery"
  );
  freeTradeGallery = freeTradeGalleryData.map((item) => ({
    src: tData<FreeTradeGalleryInterface>(item).image,
    id: item.id,
  }));
});

locale.subscribe((locale) => {
  localeType = locale;
});

$: freeTrade = freeTradeData.map((item) => ({
  ...tData<FreeTradeInterface>(item, localeType),
  id: item.id,
}));
</script>

<div class="free-trade">
  <h3>{$_("freeTradeAgreements")}</h3>
  <TextList list="{freeTrade}" padding="large" />
</div>
{#if freeTradeGallery.length}
  <ImageGallery images="{freeTradeGallery}" id="free-trade" />
{/if}
