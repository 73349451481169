<style lang="scss" global>
@import "../styles/vars";
@import "../styles/mixins";

.image-gallery {
  width: 100%;
  position: relative;
}

.image-gallery-header {
  position: absolute;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  z-index: $z-swiper-header;
}

.exclude {
  height: 2rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.image-gallery-header-start,
.image-gallery-header-end {
  width: calc(50% - 3.5rem);
  position: relative;
}

.white {
  background-color: $jp-white;
}

.blue {
  background-color: $jp-blue;
}

.grey {
  background-color: $jp-white;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($jp-grey-200, 0.15);
  }
}

.image-gallery-header-start {
  border-bottom-right-radius: 0;
}

.image-gallery-header-end {
  border-bottom-left-radius: 0;
}

.swiper-slide-image {
  width: 100%;
  height: 32.4rem;
  object-fit: cover;

  @include tablet() {
    height: 50.8rem;
  }
}

.swiper-controls {
  height: 3.2rem;
  border-radius: 1.6rem;
  padding: 0 0.8rem;
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 3.6rem;
  transform: translateX(-50%);
  background-color: rgba($jp-black, 0.5);
  z-index: $z-over-default;
}

.swiper-left,
.swiper-right {
  display: none;

  @include tablet() {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
  }
}

.swiper-pagination {
  position: relative !important;
}

.gallery-pagination {
  display: flex;
  align-items: center;
}

.swiper-pagination-bullet {
  margin: 0 0.9rem !important;
  width: 0.2rem !important;
  height: 0.2rem !important;
  border-radius: 0 !important;
  background-color: rgba($jp-white, 0.6) !important;
  transition: height 500ms, background-color 500ms !important;
}

.swiper-pagination-bullet-active {
  height: 0.8rem !important;
  background-color: $jp-white !important;
}
</style>

<script lang="ts">
import { Swiper, SwiperSlide } from "swiper/svelte";
import type { GalleryImageInterface } from "../interfaces/galleryImageInterfaces";

export let images: GalleryImageInterface[];
export let headerType: "white" | "blue" | "grey" = "white";
export let id: string;

const paginationClass = `swiper-pagination-${id}`;
</script>

{#if images}
  <div class="image-gallery">
    <div class="image-gallery-header">
      <div class="{`image-gallery-header-start ${headerType}`}"></div>
      {#if headerType === "blue"}
        <img src="/svgs/exclude-blue.svg" alt="exclude" class="exclude" />
      {:else if headerType === "grey"}
        <img src="/svgs/exclude-grey.svg" alt="exclude" class="exclude" />
      {:else}
        <img src="/svgs/exclude.svg" alt="exclude" class="exclude" />
      {/if}
      <div class="{`image-gallery-header-start ${headerType}`}"></div>
    </div>

    <Swiper
      navigation="{{
        prevEl: '.swiper-left',
        nextEl: '.swiper-right',
      }}"
      pagination="{{
        el: `.${paginationClass}`,
      }}">
      {#each images as image, index (image.id)}
        <SwiperSlide
          ><img
            class="swiper-slide-image"
            src="{image.src}"
            alt="{`Gallery Image ${index + 1}`}" /></SwiperSlide>
      {/each}
    </Swiper>

    {#if images.length > 1}
      <div class="swiper-controls">
        <img class="swiper-left" src="/svgs/caret-left.svg" alt="Left Arrow" />
        <div class="{paginationClass} gallery-pagination"></div>
        <img
          class="swiper-right"
          src="/svgs/caret-right.svg"
          alt="Right Arrow" />
      </div>
    {/if}
  </div>
{/if}
