<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.industry-rise {
  padding: 4.8rem 1.6rem;

  @include tablet {
    padding: 5.6rem 0;
  }
}

h3 {
  @include h-18-semi-bold;

  text-align: center;
}

ul {
  @include tablet {
    display: flex;
    justify-content: space-between;
    padding-top: 5.6rem;
  }
}

li {
  width: 50%;
  text-align: center;
  display: inline-block;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 4.4rem;

  @include tablet {
    width: unset;
    padding-top: 0;
  }
}

p {
  @include p-14;

  padding-bottom: 1.6rem;
  max-width: 16rem;
  text-align: center;
  margin: 0 auto;
}

img {
  width: 8.8rem;
  height: 8.8rem;
}
</style>

<script lang="ts">
import type {
  IndustryDataInterface,
  IndustryInterface,
  IndustryGalleryDataInterface,
  IndustryGalleryInterface,
} from "../interfaces/industryInterfaces";
import type { GalleryImageInterface } from "../interfaces/galleryImageInterfaces";
import axios from "axios";
import { onMount } from "svelte";
import { tData } from "../util/tData";
import ImageGallery from "../components/ImageGallery.svelte";
import ContentWrapper from "../components/ContentWrapper.svelte";
import { _ } from "svelte-i18n";
import { locale } from "svelte-i18n";
import type { LocaleType } from "../types";

let localeType: LocaleType;
let industriesData: IndustryDataInterface[] = [];
let industryGallery: GalleryImageInterface[] = [];

onMount(async () => {
  const { data: industriesResult }: { data: IndustryDataInterface[] } =
    await axios.get("/embassy-japan-industry-on-rise");
  industriesData = industriesResult;
  const {
    data: industryGalleryData,
  }: { data: IndustryGalleryDataInterface[] } = await axios.get(
    "/embassy-japan-industry-gallery"
  );
  industryGallery = industryGalleryData.map((item) => ({
    src: tData<IndustryGalleryInterface>(item).image,
    id: item.id,
  }));
});

locale.subscribe((locale) => {
  localeType = locale;
});

$: industries = industriesData.map((item) =>
  tData<IndustryInterface>(item, localeType)
);
</script>

<div class="industry-rise">
  {#if industries}
    <h3>{$_("industryOnTheRise")}</h3>
    <div class="content">
      <ContentWrapper>
        <ul>
          {#each industries as industry (industry.title)}
            <li>
              <p>{industry.title}</p>
              <img src="{industry.image}" alt="{industry.title}" />
            </li>
          {/each}
        </ul>
      </ContentWrapper>
    </div>
  {/if}
</div>
{#if industryGallery.length}
  <ImageGallery images="{industryGallery}" id="industries" />
{/if}
