<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.logo-horizontal-scroller {
  padding: 5.6rem 0 5.6rem;

  &--pb-small {
    padding-bottom: 3.6rem;
  }

  &--pb-large {
    padding-bottom: 5.6rem;
  }

  @include tablet {
    padding: 5.6rem 0 4rem;
  }
}

ul {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  @include tablet {
    justify-content: space-around;
    max-width: 111rem;
  }
}

li {
  height: 100%;
  margin-left: 1.6rem;

  &:not(:last-child) {
    margin-right: 7.2rem;
  }

  &:last-child {
    padding-right: 1.6rem;
  }

  @include tablet {
    margin: 0;
    padding: 0;
  }
}

img {
  height: 100%;
}
</style>

<script lang="ts">
import type { LogoInterface } from "../interfaces/logoInterfaces";

export let logos: LogoInterface[];
export let paddingBottom: "small" | "large" = "large";
</script>

<div
  class="{`logo-horizontal-scroller logo-horizontal-scroller--pb-${paddingBottom}`}">
  <ul>
    {#each logos as item (item.id)}
      <li><img src="{item.logo}" alt="Logo" /></li>
    {/each}
  </ul>
</div>
