<style lang="scss" global>
@import "../styles/vars";
@import "../styles/mixins";

.tourism-potential {
  padding: 4.8rem 1.6rem 4.4rem;

  @include tablet {
    padding: 8.8rem 0 7.6rem;
  }

  &__content {
    padding-top: 4.4rem;

    @include tablet {
      padding-top: 7.2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__content-text {
    @include p-14;

    width: 100%;

    @include tablet {
      width: 43.2rem;
    }

    p {
      &:not(:first-child) {
        padding-top: 3.6rem;
      }

      strong {
        @include p-14-semi-bold;

        color: $jp-red !important;
      }
    }
  }

  &__content-image {
    max-width: 52.4rem;
    width: 100%;
    margin: 4rem auto 0;
    display: block;

    @include tablet {
      margin: 0;
      padding: 0;
    }
  }

  h2 {
    @include h-28-semi-bold;

    text-align: center;
  }

  &__bullets {
    text-align: center;
    padding: 4rem 1.6rem 4.4rem;

    @include tablet {
      padding: 8.8rem 0 7.6rem;
    }

    ul {
      @include tablet {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    li {
      @include h-18-medium;

      width: 100%;
      padding-top: 0.8rem;

      @include tablet {
        max-width: 32rem;
        text-align: left;
        padding-top: 0;
      }

      @include desktop-sm {
        &:nth-child(2),
        &:nth-child(5) {
          padding-left: 5rem;
        }
      }
    }
  }
}
</style>

<script lang="ts">
import type {
  tourismInterface,
  tourismDataInterface,
  tourismBulletInterface,
  tourismBulletDataInterface,
  tourismGalleryInterface,
  tourismGalleryDataInterface,
} from "../interfaces/tourismInterfaces";
import axios from "axios";
import { onMount } from "svelte";
import { tData } from "../util/tData";
import ImageGallery from "../components/ImageGallery.svelte";
import ContentWrapper from "../components/ContentWrapper.svelte";
import { _ } from "svelte-i18n";
import navigation from "../store/navigation";
import { locale } from "svelte-i18n";
import type { LocaleType } from "../types";

let localeType: LocaleType;
let tourismEl: HTMLDivElement;
let tourismState: tourismDataInterface;
let tourusmGalleryOneState: tourismGalleryDataInterface[] = [];
let tourismBulletsState: tourismBulletDataInterface[] = [];
let tourusmGalleryTwoState: tourismGalleryDataInterface[] = [];

onMount(async () => {
  navigation.setElement(tourismEl, "tourismPotential");

  const { data: tourismData }: { data: tourismDataInterface[] } =
    await axios.get("/embassy-japan-tour-potential");
  tourismState = tourismData[0];

  const {
    data: tourusmGalleryOneData,
  }: { data: tourismGalleryDataInterface[] } = await axios.get(
    "/embassy-japan-tourism-gallery"
  );
  tourusmGalleryOneState = tourusmGalleryOneData;

  const { data: tourismBulletsData }: { data: tourismBulletDataInterface[] } =
    await axios.get("/embassy-japan-tourism-bullets");
  tourismBulletsState = tourismBulletsData;

  const {
    data: tourusmGalleryTwoData,
  }: { data: tourismGalleryDataInterface[] } = await axios.get(
    "/embassy-japan-tourism-gallery-2"
  );
  tourusmGalleryTwoState = tourusmGalleryTwoData;
});

locale.subscribe((locale) => {
  localeType = locale;
});

$: tourism = tData<tourismInterface>(tourismState, localeType);
$: tourusmGalleryOne = tourusmGalleryOneState.map((item) => ({
  src: tData<tourismGalleryInterface>(item, localeType).image,
  id: item.id,
}));
$: tourismBullets = tourismBulletsState.map((item) =>
  tData<tourismBulletInterface>(item, localeType)
);
$: tourusmGalleryTwo = tourusmGalleryTwoState.map((item) => ({
  src: tData<tourismGalleryInterface>(item, localeType).image,
  id: item.id,
}));
</script>

<div class="tourism-potential" bind:this="{tourismEl}">
  <h2>{$_("tourismPotential")}</h2>
  {#if tourism}
    <ContentWrapper>
      <div class="tourism-potential__content">
        <div class="tourism-potential__content-text">
          {@html tourism.text}
        </div>

        <img
          class="tourism-potential__content-image"
          src="{tourism.image}"
          alt="Statistics" />
      </div>
    </ContentWrapper>
  {/if}
</div>
{#if tourusmGalleryOne.length}
  <ImageGallery images="{tourusmGalleryOne}" id="tourism-one" />
{/if}
{#if tourismBullets}
  <div class="tourism-potential__bullets">
    <ContentWrapper>
      <ul>
        {#each tourismBullets as bullet (bullet.title)}
          <li>{bullet.title}</li>
        {/each}
      </ul>
    </ContentWrapper>
  </div>
{/if}
{#if tourusmGalleryTwo.length}
  <ImageGallery images="{tourusmGalleryTwo}" id="tourism-two" />
{/if}
