<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.main-banner {
  width: 100%;
  height: 54.4rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 20%;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.39) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  @include tablet {
    height: 58.8rem;
  }
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $jp-white;
  text-align: center;
}

h1 {
  @include h-108-medium;
}

h2 {
  @include h-18;
}
</style>

<script lang="typescript">
import axios from "axios";
import { onMount } from "svelte";
import type {
  MainBannerDataInterface,
  MainBannerInterface,
} from "../interfaces/mainBannerInterfaces";
import { tData } from "../util/tData";
import { locale } from "svelte-i18n";
import type { LocaleType } from "../types";

let bannerDataState: MainBannerDataInterface;
let localeType: LocaleType;

onMount(async () => {
  const { data }: { data: MainBannerDataInterface[] } = await axios.get(
    "/embassy-japan-main-banner"
  );
  bannerDataState = data[0];
});

locale.subscribe((locale) => {
  localeType = locale;
});

$: bannerData = tData<MainBannerInterface>(bannerDataState, localeType);
</script>

<div
  class="main-banner"
  style="{`background-image: url(${
    bannerData ? bannerData.image : undefined
  })`}">
  {#if bannerData}
    <div class="title">
      <h1>
        {bannerData.title}
      </h1>
      <h2>
        {bannerData.subTitle}
      </h2>
    </div>
  {/if}
</div>
