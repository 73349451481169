<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.country-profile {
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  display: none;

  @include tablet() {
    display: block;
  }
}

.mobile-image {
  width: 100%;

  @include tablet() {
    display: none;
  }
}

.info {
  padding: 4.8rem 0 3.2rem;

  @include tablet() {
    padding: 0;
    position: absolute;
    top: 5.6rem;
    left: 50%;
    transform: translateX(-50%);
  }

  @include desktop-sm() {
    top: 8.8rem;
  }
}

.details {
  display: block;
  padding: 0 1.6rem;

  @include tablet() {
    display: flex;
    padding: 7.2rem 0 0;
  }
}

h2 {
  @include h-28-semi-bold;

  text-align: center;
}

ul {
  width: 100%;
  padding: 3.6rem 0 0 0;

  @include tablet() {
    width: 30.4rem;
    padding: 0 3.2rem 0 0;
    flex-shrink: 0;

    &:last-child {
      padding: 0;
    }
  }

  @include desktop-sm() {
    width: 37.6rem;
    padding: 0 5.6rem 0 0;
  }
}

li {
  @include h-16;

  padding-top: 0.8rem;

  strong {
    @include h-16-semi-bold;

    color: $jp-red;
  }
}
</style>

<script lang="typescript">
import axios from "axios";
import { onMount } from "svelte";
import ImageGallery from "../components/ImageGallery.svelte";
import type {
  CountryProfileInterface,
  CountryProfileDataInterface,
  CountryProfileInfoDataInterface,
  CountryProfileInfoInterface,
} from "../interfaces/countryProfileInterfaces";
import type {
  GalleryImageInterface,
  GalleryImageDataInterface,
} from "../interfaces/galleryImageInterfaces";
import { tData } from "../util/tData";
import { _ } from "svelte-i18n";
import navigation from "../store/navigation";
import { locale } from "svelte-i18n";
import type { LocaleType } from "../types";

let localeType: LocaleType;
let countryProfileEl: HTMLDivElement;
let countryProfileData: CountryProfileDataInterface;
let countryProfileInfo: CountryProfileInfoDataInterface[];
let countryProfileImageGalleryData: GalleryImageDataInterface[] = [];

const getCountryInfo = (
  countryProfileInfoItem: CountryProfileInfoDataInterface,
  localeType: LocaleType
) => {
  return tData<CountryProfileInfoInterface>(countryProfileInfoItem, localeType);
};

onMount(async () => {
  navigation.setElement(countryProfileEl, "countryProfile");

  const {
    data: countryProfileDataResult,
  }: { data: CountryProfileDataInterface[] } = await axios.get(
    "/embassy-japan-country-profile"
  );
  const {
    data: countryProfileInfoDataResult,
  }: { data: CountryProfileInfoDataInterface[] } = await axios.get(
    "/embassy-japan-country-prof-data"
  );
  const {
    data: galleryImagesDataResult,
  }: { data: GalleryImageDataInterface[] } = await axios.get(
    "/embassy-japan-country-prof-gallery"
  );

  countryProfileData = countryProfileDataResult[0];
  countryProfileInfo = countryProfileInfoDataResult;
  countryProfileImageGalleryData = galleryImagesDataResult;
});

locale.subscribe((locale) => {
  localeType = locale;
});

$: countryProfile = tData<CountryProfileInterface>(
  countryProfileData,
  localeType
);
$: countryProfileImageGallery = countryProfileImageGalleryData.map((item) => ({
  ...tData<GalleryImageInterface>(item, localeType),
  id: item.id,
}));
</script>

<div class="country-profile" bind:this="{countryProfileEl}">
  {#if countryProfile}
    <img
      class="image"
      src="{countryProfile.image}"
      alt="{countryProfile.title}" />
  {/if}

  <div class="info">
    {#if countryProfile}
      <h2>{$_("countryProfile")}</h2>
    {/if}

    {#if countryProfileInfo}
      <div class="details">
        <ul>
          <!-- TODO: better to move in separate component instead -->
          {#each countryProfileInfo.slice(0, 4) as item (item.id)}
            <li>
              {getCountryInfo(item, localeType).label}:
              <strong>
                {getCountryInfo(item, localeType).value}
              </strong>
            </li>
          {/each}
        </ul>
        <ul>
          {#each countryProfileInfo.slice(4, 8) as item (item.id)}
            <li>
              {getCountryInfo(item, localeType).label}:
              <strong>
                {getCountryInfo(item, localeType).value}
              </strong>
            </li>
          {/each}
        </ul>
        <ul>
          {#each countryProfileInfo.slice(8, 12) as item (item.id)}
            <li>
              {getCountryInfo(item, localeType).label}:
              <strong>
                {getCountryInfo(item, localeType).value}
              </strong>
            </li>
          {/each}
        </ul>
      </div>
    {/if}
  </div>

  {#if countryProfile}
    <img
      class="mobile-image"
      src="{countryProfile.mobileImage}"
      alt="{countryProfile.title}" />
  {/if}

  {#if countryProfileImageGallery.length}
    <ImageGallery images="{countryProfileImageGallery}" id="country-profile" />
  {/if}
</div>
