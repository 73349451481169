<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.general-info {
  text-align: center;
  padding: 4.8rem 1.6rem 0;

  @include tablet {
    padding: 8.8rem 0 0;
  }

  &__container {
    display: inline-block;
    margin: 0 auto;
    border-bottom: 0.1rem solid rgba($jp-grey-200, 0.3);
    padding-bottom: 4.8rem;

    @include tablet {
      padding-bottom: 9.6rem;
      width: 88rem;
    }

    @include desktop-sm {
      width: 111rem;
    }
  }

  &__logo {
    height: 14rem;
  }

  &__description {
    @include p-14;

    margin: 0 auto;
    max-width: 72rem;
    padding-top: 3.6rem;
  }

  &__list {
    display: inline-block;
    padding-top: 2.6rem;
    text-align: center;

    @include tablet {
      padding-top: 6.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ul {
      @include tablet {
        &:first-child {
          padding-right: 4.8rem;
        }
      }

      li {
        @include p-14;

        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1.8rem;
        text-align: left;

        @include tablet {
          @include h-18;

          padding-top: 2.8rem;
          justify-content: flex-start;
        }

        img {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1.2rem;
        }
      }
    }
  }
}
</style>

<script lang="typescript">
import axios from "axios";
import { onMount } from "svelte";
import type {
  GeneralInfoDataInterface,
  GeneralInfoInterface,
} from "../interfaces/generalInfoInterfaces";
import { tData } from "../util/tData";
import { locale } from "svelte-i18n";

let localeType = null;
let generalInfoData: GeneralInfoDataInterface;

onMount(async () => {
  const { data }: { data: GeneralInfoDataInterface[] } = await axios.get(
    "/embassy-japan-general-info"
  );

  generalInfoData = data[0];
});

locale.subscribe((locale) => {
  localeType = locale;
});

$: generalInfo = tData<GeneralInfoInterface>(generalInfoData, localeType);
</script>

<div class="general-info">
  <div class="general-info__container">
    {#if generalInfo}
      <img class="general-info__logo" src="{generalInfo.logo}" alt="Logo" />

      <div class="general-info__description">
        {@html generalInfo.description}
      </div>

      <div class="general-info__list">
        <ul>
          <li>
            <img src="/svgs/hand-coin-line.svg" alt="icon" />
            {generalInfo.titleOne}
          </li>
          <li>
            <img src="/svgs/bank-line.svg" alt="icon" />
            {generalInfo.titleTwo}
          </li>
        </ul>
        <ul>
          <li>
            <img src="/svgs/percent-line.svg" alt="icon" />
            {generalInfo.titleThree}
          </li>
          <li>
            <img src="/svgs/map-2-line.svg" alt="icon" />
            {generalInfo.titleFour}
          </li>
        </ul>
      </div>
    {/if}
  </div>
</div>
