<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.contact {
  padding: 0 1.6rem 0;

  @include tablet {
    padding: 9.6rem 0 0;
  }

  &__title {
    @include p-24-semi-bold;

    text-align: left;
    font-weight: 500;
  }
}

.content {
  border-bottom: 0.1rem solid rgba($jp-grey-200, 0.3);
  padding-bottom: 6.4rem;

  @include tablet {
    display: flex;
  }
}

.section {
  padding-top: 4.8rem;

  @include tablet {
    width: 50%;
    padding: 0;
  }
}

.contact-list {
  padding-top: 0.8rem;
}

.contact-item {
  display: flex;
  justify-items: center;
  padding-top: 0.8rem;
}

.contact-icon {
  width: 1.8rem;
  height: 1.8rem;
}

.contact-label {
  @include p-14;

  font-weight: 500;
  padding-left: 0.8rem;
}

.website-link {
  @include p-14;

  padding-top: 0.4rem;
  display: block;
}

.social {
  display: flex;
  align-items: center;
  padding-top: 2.4rem;

  a {
    display: block;
    width: 3.2rem;
    height: 3.2rem;

    &:not(:last-child) {
      margin-right: 1.6rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.logo {
  height: 8rem;
  margin-top: 2.4rem;
}

ul {
  padding: 2.4rem 0 6.4rem;
  text-align: center;

  @include tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

.logo-item {
  a {
    height: 4rem;
    text-align: center;
    margin-top: 4.4rem;
  }

  @include tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  img {
    height: 100%;
  }
}
</style>

<script lang="ts">
import type {
  ContactInterface,
  ContactDataInterface,
  ContactLogoInterface,
  ContactLogoDataInterface,
} from "../interfaces/contactInterfaces";
import axios from "axios";
import { onMount } from "svelte";
import { tData } from "../util/tData";
import ContentWrapper from "../components/ContentWrapper.svelte";
let contactState: ContactDataInterface;
let contactLogosState: ContactLogoDataInterface[] = [];

onMount(async () => {
  const { data: contactData }: { data: ContactDataInterface[] } =
    await axios.get("/embassy-japan-contact");
  contactState = contactData[0];

  const { data: contactLogosData }: { data: ContactLogoDataInterface[] } =
    await axios.get("/embassy-japan-contact-logos");
  contactLogosState = contactLogosData;
});

$: contact = tData<ContactInterface>(contactState);
$: contactLogos = contactLogosState.map((item) =>
  tData<ContactLogoInterface>(item)
);

const contacts = ["email", "tel"];
const socials = ["facebook", "twitter", "youtube"];
</script>

<div class="contact">
  <ContentWrapper>
    {#if contact}
      <div class="content">
        <div class="section">
          <h2 class="contact__title">{contact.titleOne}</h2>

          <div class="contact-list">
            {#each contacts as contactItem (contactItem)}
              <div class="contact-item">
                <img
                  src="{`/svgs/${contactItem}.svg`}"
                  alt="{contactItem}"
                  class="contact-icon" />

                {#if contactItem === "email"}
                  <a
                    href="{`mailto:${contact[contactItem]}`}"
                    class="contact-label">{contact[contactItem]}</a>
                {:else}
                  <span class="contact-label">
                    {contact[contactItem]}
                  </span>
                {/if}
              </div>
            {/each}
          </div>

          <div class="social">
            {#each socials as social (social)}
              <a href="{contact[social]}" target="_blank">
                <img src="{`/svgs/${social}.svg`}" alt="{social}" />
              </a>
            {/each}
          </div>
        </div>
        <div class="section">
          <h2 class="contact__title">{contact.titleTwo}</h2>
          <a class="website-link" href="{contact.websiteLink}"
            >{contact.website}</a>
          <img class="logo" src="{contact.logo}" alt="Logo" />
        </div>
      </div>
    {/if}

    {#if contactLogos}
      <ul>
        {#each contactLogos as logo, i (i)}
          <li class="logo-item">
            <a href="{logo.link}">
              <img src="{logo.image}" alt="Logo" />
            </a>
          </li>
        {/each}
      </ul>
    {/if}
  </ContentWrapper>
</div>
