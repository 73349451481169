<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.bilateral-relations {
  padding: 4.8rem 1.6rem 6.4rem;

  @include tablet {
    padding: 8.8rem 0 9.6rem;
  }
}

h3 {
  @include h-28-semi-bold;

  text-align: center;
  padding-bottom: 2rem;

  @include tablet {
    padding-bottom: 0.8rem;
  }
}
</style>

<script lang="ts">
import type {
  BilateralInterface,
  BilateralDataInterface,
} from "../interfaces/bilateralInterfaces";
import axios from "axios";
import { _ } from "svelte-i18n";
import { onMount } from "svelte";
import { tData } from "../util/tData";
import TextList from "../components/TextList.svelte";
import navigation from "../store/navigation";
import { locale } from "svelte-i18n";
import type { LocaleType } from "../types";

let localeType: LocaleType;
let bilateralRelationsEl: HTMLDivElement;
let taxationSystemIconsData: BilateralDataInterface[] = [];

onMount(async () => {
  navigation.setElement(bilateralRelationsEl, "bilateralRelations");

  const {
    data: taxationSystemIconsResult,
  }: { data: BilateralDataInterface[] } = await axios.get(
    "/embassy-japan-bilateral"
  );

  taxationSystemIconsData = taxationSystemIconsResult;
});

locale.subscribe((locale) => {
  localeType = locale;
});

$: bilateral = taxationSystemIconsData.map((item) => ({
  ...tData<BilateralInterface>(item, localeType),
  id: item.id,
}));
</script>

<div class="bilateral-relations" bind:this="{bilateralRelationsEl}">
  <h3>{$_("bilateralRelations")}</h3>

  <TextList list="{bilateral}" padding="{'large'}" />
</div>
